import { isFunction } from 'underscore';
import { observable, observe, unobserve } from '@nx-js/observer-util';
import IMOG from '~/lib/imog';
import { ticker as Ticker } from '~/lib/imog';

export default ({ target, k = -30, b = -0.97 } = {}) => {
  const context = IMOG.getContext();
  const reactions = [];

  let targetValue;

  let a = 0;
  let v = 0;
  const mass = 0.1;

  const result = observable({ value: 0 });

  const tick = (ms, dt) => {
    var spring = k * (result.value - targetValue);
    var damper = b * v;
    // update acceleration
    a = (spring + damper) / 0.1;
    // update velocity
    v += a * 16;
    // update position
    result.value += v * 16;

    // result.value += (targetValue - result.value) * _friction;
  };

  IMOG.onSetup(() => {
    result.value = target(context.props);
    reactions.push(
      observe(() => {
        targetValue = target(context.props);
      })
    );

    Ticker.add(tick, 'propWhileHook');
  });

  IMOG.onDestroy(() => {
    Ticker.remove(tick);
    reactions.forEach(unobserve);
  });

  return result;
};
